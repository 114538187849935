@import '~animate.css/animate.min.css';

@import "chartist/chartist";

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import "theme/variables";
@import "style";
@import "theme/responsive";

@import "todo/todo";
@import 'sweetalert/sweetalert2.scss';
@import "sticky/sticky.scss";

